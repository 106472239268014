export default [
  "Abroromantic",
  "Androromantic",
  "Aromantic",
  "Biromantic",
  "Ceteroromantic",
  "Demiromantic",
  "Frayromantic",
  "Gray-Romantic",
  "Gyneromantic",
  "Heteroromantic",
  "Homoromantic",
  "Monoromantic",
  "None",
  "Omniromantic",
  "Panromantic",
  "Polyromantic",
  "Recipromantic"
]

export default [
  {label: "Dominant Partner", value: "dom"},
  {label: "Submissive Partner", value: "sub"},
  {label: "Master", value: "master"},
  {label: "Mistress", value: "mistress"},
  {label: "Slave", value: "slave"},
  {label: "Play Partner", value: "playpartner"},
  {label: "Relationship", value: "relationship"},
  {label: "Long Term Relationship", value: "ltr"},
  {label: "A Dynamic", value: "dynamic"},
  {label: "Friendship", value: "friendship"},
  {label: "Mentorship", value: "mentorship"},
  {label: "Community", value: "community"},
  {label: "Events", value: "events"}
]

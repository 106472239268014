export default [
  "Ageplayer",
  "Angel",
  "Babyboy",
  "Babygirl",
  "Big",
  "Bootblack",
  "Bottom",
  "Brat",
  "Caregiver",
  "Cougar",
  "Cub",
  "Cuckcake",
  "Cuckold",
  "Cuckquean",
  "Cuckoldress",
  "Daddy",
  "Demon",
  "Devil",
  "Disciplinarian",
  "Doll",
  "Dollmaker",
  "Dominant",
  "Domme",
  "Drag King",
  "Drag Queen",
  "Edge Player",
  "Electro Bottom",
  "Electro Switch",
  "Electro Top",
  "Evolving",
  "Exhibitionist",
  "Exploring",
  "Feminizer",
  "Fetishist",
  "Fuckdoll",
  "Fucktoy",
  "Furry",
  "Good Boy",
  "Good Girl",
  "Handler",
  "Hedonist",
  "Hotwife",
  "Incubus",
  "Kajira",
  "Kajirus",
  "Kinkster",
  "Kitten",
  "Leather bottom",
  "Leather Daddy",
  "Leather Mommy",
  "Leather Top",
  "Leatherboi",
  "Leatherboy",
  "Leathergirl",
  "Leatherman",
  "Leatherwoman",
  "Little",
  "Masochist",
  "Master",
  "Middle",
  "Mistress",
  "Mommy",
  "Not Applicable",
  "Owner",
  "Pet",
  "Pony",
  "Primal Hunter",
  "Primal Predator",
  "Primal Prey",
  "Primal",
  "Princess",
  "Property",
  "Pup",
  "Rigger",
  "Rope Bottom",
  "Rope Bunny",
  "Rope Top",
  "Rubberist",
  "Sadist",
  "Sadomasochist",
  "Sensualist",
  "Service Brat",
  "Service Sub",
  "Service Top",
  "Sissy",
  "Slave",
  "Slut",
  "Spankee",
  "Spanker",
  "Spanko",
  "Stag",
  "Submissive",
  "Succubus",
  "Swinger",
  "Switch",
  "Top",
  "Toy",
  "Undecided",
  "Vanilla",
  "Vixen",
  "Voyeur"
]

export default [
  "Abrosexual",
  "Aceflux",
  "Androsexual",
  "Asexual",
  "Bisexual",
  "Demisexual",
  "Dyke",
  "Fluctuating/Evolving",
  "Fraysexual",
  "Gay",
  "Graysexual",
  "Gynesexual",
  "Heteroflexible",
  "Homoflexible",
  "Lesbian",
  "Neptunic",
  "Noetisexual",
  "None",
  "Omnisexual",
  "Pansexual",
  "Polysexual",
  "Queer",
  "Questioning",
  "Reciprosexual",
  "Sapiosexual",
  "Straight",
  "Unsure",
  "Uranic"
]

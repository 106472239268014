export default [
  "Agender",
  "Androgyne",
  "Aporagender",
  "Bigender",
  "Butch",
  "Demiboy",
  "Demigirl",
  "Femme",
  "Genderfluid",
  "Genderflux",
  "Genderfuck",
  "Genderless",
  "Genderqueer",
  "Gendervague",
  "Indigender",
  "Intersex",
  "Man",
  "Masc",
  "Neutrois",
  "Non-Binary",
  "None",
  "Pangender",
  "Trans Man",
  "Trans Woman",
  "Transgender",
  "Two-Spirit",
  "Unsure",
  "Woman"
]
